import { useCallback, useEffect, useMemo, useState } from "react";
import { TokenModel } from "../../../../model/interface/TokenModel";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import { getReverseAuctionDetailsAPI } from "../../../../redux/functions/API";
import { useParams } from "react-router-dom";
import ReverseAuctionComponent from "../../../templates/ReverseAuctionComponent/ReverseAuctionComponent";
import { TruckIcon, UserIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
	getReverseAuctionDetails,
	setReverseAuctionDetails,
} from "../../../../redux/functions/storageSlice";

const BuyerReverseAuctionComp = () => {
	// get and decode the token from local storage
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);

	const [cardData, setCardData] = useState<any>([]);
	//const [reverseAuctionDetails, setReverseAuctionDetails] = useState<any>({});
	const dispatch = useAppDispatch();
	const reverseAuctionDetails = useAppSelector(getReverseAuctionDetails);
	const [isRefreshing, setisRefreshing] = useState<boolean>(true);
	const [supplierData, setSupplierData] = useState<any>([]); // Store supplier data here

	// get the url params
	const { reverse_auction_id } = useParams();

	const supplierDetails = useMemo(() => {
		return Array.isArray(supplierData) ? supplierData : [];
	}, [supplierData]);

	// Find the selected supplier, or fall back to the cheapest supplier
	const selectedSupplier = useMemo(() => {
		return (
			supplierDetails.find((supplier: any) => supplier.is_selected) || 
			reverseAuctionDetails?.supplier_data?.cheapestSupplier
		);
	}, [supplierDetails, reverseAuctionDetails]);

	// cja: this hook is the main functionality in displaying the data in the buyer terminal reverse auction page.
	// It's main dependency is "isRefreshing" local state, if the value of this state will be true,
	// this hook will be executed to refetch the updated reverse auction data.
	useEffect(() => {
		if (isRefreshing) {
			// Make the API call
			getReverseAuctionDetailsAPI(
				_token,
				reverse_auction_id ?? "",
				userData.role
			)
				.then((data: any) => {
					// Extracting relevant data from the response
					const {
						reverse_auction_data,
						buyer_data,
						supplier_data,
						logistic_data,
					} = data;

					// Dispatch the action to update the state
					dispatch(
						setReverseAuctionDetails({
							reverse_auction_data: reverse_auction_data || {},
							buyer_data: buyer_data || {},
							supplier_data: supplier_data || {},
							logistic_data: logistic_data || {},
						})
					);
					// Buyer details card
					const buyerDetailsOfferCard = {
						name:
							buyer_data?.buyer_details?.company_name || "No buyer available",
						icon: UserIcon,
						role: "Buyer",
						imageUrl:
							buyer_data?.buyer_details?.company_logo || "default-image-url",
						count: buyer_data?.buyer_details ? 1 : 0,
					};

					// const selectedSupplier = supplier_data?.supplier_details?.find(
					// 	(supplier: any) => supplier.is_selected
					// );
					// Store supplier data in state
					setSupplierData(supplier_data?.supplier_details || []);

					const supplierDetailsOfferCard = {
						name:
							selectedSupplier?.supplier_details?.company_name ||
							"No selected supplier available",
						icon: TruckIcon,
						role: "Supplier",
						imageUrl:
							selectedSupplier?.supplier_details?.company_logo ||
							"default-image-url",
						count: supplier_data?.supplier_details?.length || 0,
					};

					let logisticDetailsOfferCard = {
						name: "No logistic available",
						icon: TruckIcon,
						role: "Logistic",
						imageUrl: "default-image-url",
						count: 0,
					};

					if (
						supplier_data?.cheapestSupplier?.shipping_method_id === 1 &&
						reverse_auction_data?.shipping_method_id === 1
					) {
						logisticDetailsOfferCard = {
							name: "Supplier's logistic",
							icon: TruckIcon,
							role: "Logistic",
							imageUrl:
								supplier_data?.cheapestSupplier?.company_logo ||
								"default-image-url",
							count: 1,
						};
					} else if (
						supplier_data?.cheapestSupplier?.shipping_method_id === 2 &&
						reverse_auction_data?.shipping_method_id === 2
					) {
						logisticDetailsOfferCard = {
							name:
								logistic_data?.cheapestLogistic?.logistic_details
									?.company_name || "Buyer's logistic",
							icon: TruckIcon,
							role: "Logistic",
							imageUrl:
								logistic_data?.cheapestLogistic?.logistic_details
									?.company_logo || "default-image-url",
							count: 1,
						};
					} else if (logistic_data?.logistic_details) {
						logisticDetailsOfferCard = {
							name:
								logistic_data?.cheapestLogistic?.logistic_details
									?.company_name || "No logistic available",
							icon: TruckIcon,
							role: "Logistic",
							imageUrl:
								logistic_data?.cheapestLogistic?.logistic_details
									?.company_logo || "default-image-url",
							count: logistic_data?.logistic_details?.length || 0,
						};
					}

					setCardData([
						buyerDetailsOfferCard,
						supplierDetailsOfferCard,
						logisticDetailsOfferCard,
					]);

					// Turn off refreshing state
					setisRefreshing(false);
				})
				.catch((error: any) => {
					console.error("Error fetching data:", error);
					// Turn off refreshing state even in case of error
					setisRefreshing(false);
				});
		}
	}, [
		dispatch,
		_token,
		userData.role,
		isRefreshing,
		reverse_auction_id,
		selectedSupplier?.supplier_details?.company_logo,
		selectedSupplier?.supplier_details?.company_name,
	]);

	const updateCardData = useCallback((updatedCardData: any) => {
		setCardData(updatedCardData);
	}, []);

	const refreshHandler = useCallback(() => {
		setisRefreshing(true);
	}, []);

	return (
		<>
			<ReverseAuctionComponent
				user_data={userData}
				card_data={cardData}
				reverse_auction_id={reverse_auction_id ?? ""}
				reverse_auction_details={reverseAuctionDetails}
				refreshHandler={refreshHandler}
				isRefreshing={isRefreshing}
				updateCardData={updateCardData}
			/>
		</>
	);
};
export default BuyerReverseAuctionComp;
