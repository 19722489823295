import React, { useEffect, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import {
	ArrowDownCircleIcon,
	ArrowPathIcon,
	CalendarDaysIcon,
	CircleStackIcon,
	MapPinIcon,
	UserIcon,
} from "@heroicons/react/24/outline";
import logo from "../../buyer/my_order/dealogikal-app-dark_p4svpl.png";
import TableComponent from "../../templates/TableComponent/TableComponent";
import Skeleton from "react-loading-skeleton";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
	getCommentDetails,
	getShipments,
	setCommentDetails,
	setShipmentsList,
	setForATLTAbleList,
	getforATLTablelist,
	setToShipList,
	setForToShipDetails,
	getForToShipDetails,
	setDeliveryLists,
	setDeliveryDetailsLists,
} from "../../../redux/functions/storageSlice";
import {
	atlConfirmationAPI,
	getATL_list_API,
	getCloseOrdersAPI,
	getDeliveryDetailsAPI,
	getDeliveryListAPI,
	getToShipDetailsAPI,
	getToShipListsAPI,
	updateConfirmToDeliverAPI,
	verificationAPI,
} from "../../../redux/functions/API";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import CircularLoading from "../../../lottie/circular_loading_theme_1.json";
import { TokenModel } from "../../../model/interface/TokenModel";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import ShipmentListType from "../../../model/types/ShipmentsListType";
import DropdownItemType from "../../../model/types/DropdownItemType";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";
import DropdownOptionComponent from "../../templates/DropdownOptionComponent/DropdownOptioncomponent";
import ButtonComponent from "../../templates/ButtonComponent/ButtonComponent";
import ToasterComponent from "../../templates/ToasterComponent/ToasterComponent";
import { ATLTablelistType } from "../../../model/types/Authorization";
import Lottie from "lottie-react";
import { useWebSocket } from "../../../services/ws/WebSocketService";
import ToShipDetailsType from "../../../model/types/ToShipDetailsType";
import DeliveryListType from "../../../model/types/DeliveryListType";

const ShipmentsComp = () => {
	const dispatch = useAppDispatch();
	const myShipment = useAppSelector(getShipments);
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);

	const { socket, sendWebSocketMessage } = useWebSocket();

	const contentRef = useRef<HTMLDivElement>(null);
	const commentsDetails = useAppSelector(getCommentDetails);
	const ATLlist = useAppSelector(getforATLTablelist);
	const toShipDetailsStateValue = useAppSelector(getForToShipDetails);
	const [activeNav, setActiveNav] = useState<string>("All");
	const [isAllFetching, setIsAllFetching] = useState<boolean>(true);
	const [allShipmentList, setAllShipmentList] = useState<ShipmentListType[]>(
		myShipment.allShipmentsList
	);
	const [toShipLists, setToShipLists] = useState<ATLTablelistType[]>(
		myShipment.toShipList
	);
	const [isToShipFetching, setIsToShipFetching] = useState<boolean>(true);
	const [paymentVerificationList, setPaymentVerificationList] = useState<
		ShipmentListType[]
	>(myShipment.allShipmentsList);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
	const [selectedOption, setSelectedOption] = useState<string>("");
	const [isCommentDetailsEmpty, setIsCommentDetailsEmpty] =
		useState<boolean>(false);
	const [isAtlModalOpen, setIsAtlModalOpen] = useState<boolean>(false);
	const [isATL_Fetching, setIsATL_Fetching] = useState<boolean>(true);
	const [forATLTablePageList, setForATLTablePageList] =
		useState<ATLTablelistType[]>(ATLlist);
	const [forToShipPageDetails, setForToShipPageDetails] = useState<
		ToShipDetailsType[]
	>(toShipDetailsStateValue);
	const [deliveryList, setDeliveryList] = useState<DeliveryListType[]>(
		myShipment.deliveryLists
	);
	const [isDeliveryListFetching, setIsDeliveryListFetching] =
		useState<boolean>(true);
	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");
	const [toastType, setToastType] = useState<string>("");

	const [deliveryID, setDeliveryID] = useState(0);
	const [isDeliveryModalOpen, setIsDeliveryModalOpen] =
		useState<boolean>(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isLoadingAPI, setIsLoadingAPI] = useState(false);
	const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
	const [isConfirmingATL, setIsConfirmingATL] = useState<boolean>(false);

	const nav_items = [
		{
			text: "All",
		},
		{
			text: "Payment Verification",
		},
		{
			text: "ATL",
		},
		{
			text: "To Ship",
		},
		{
			text: "Delivery",
		},
	];

	const fieldChangeHandler = (value: string | any, name: string) => {
		switch (name) {
			case "txtcommentsdetails":
				dispatch(setCommentDetails(value));
				break;
			default:
				break;
		}
	};

	const setActiveNavTab = (active_item: string) => {
		setActiveNav(active_item);
	};

	const allHeader = [
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "buyer_location",
			value: "Buyer Location",
			icon: MapPinIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "supplier_location",
			value: "Supplier Depo",
			icon: MapPinIcon,
		},
		{
			name: "logistic_rate",
			value: "Amount",
			icon: CircleStackIcon,
		},
		{
			name: "is_disbursement_verified",
			value: "Status",
			icon: ArrowDownCircleIcon,
			customRender: (item: any) => (
				<span
					className={
						item.is_disbursement_verified === "For Verification"
							? "text-dealogikal-50"
							: item.is_disbursement_verified === "Verified"
							? "text-green-500"
							: ""
					}
				>
					{item.is_disbursement_verified}
				</span>
			),
		},
	];

	const ATLheader = [
		{
			name: "order_number",
			value: "DL number",
			icon: UserIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "supplier_depot",
			value: "Depot Address",
			icon: UserIcon,
		},
		{
			name: "order_status",
			value: "Status",
			icon: UserIcon,
			customRender: (item: any) => (
				<span
					className={
						item.order_status === "On-going"
							? "text-orange-300"
							: item.order_status === "Complete"
							? "text-green-500"
							: ""
					}
				>
					{item.order_status}
				</span>
			),
		},
	];

	const ToShipHeader = [
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "buyer_location",
			value: "Buyer Location",
			icon: MapPinIcon,
		},
		{
			name: "supplier_depot",
			value: "Depot Address",
			icon: UserIcon,
		},
		{
			name: "requested_delivery_date",
			value: "Delivery Date",
			icon: CalendarDaysIcon,
		},
	];

	const deliveryHeader = [
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "order_status",
			value: "Order Status",
			icon: ArrowPathIcon,
		},
		{
			name: "date_delivered",
			value: "Date Delivery",
			icon: CalendarDaysIcon,
		},
		{
			name: "date_completed",
			value: "Date Completed",
			icon: CalendarDaysIcon,
		},
	];

	const selectedForVerification = myShipment.allShipmentsList.find(
		(list: any) => list.disbursement_details_id === selectedRowId
	);

	const selectedATL = ATLlist.find(
		(list: any) => list.offer_booking_id === selectedRowId
	);

	// fetching to ship list
	useEffect(() => {
		if (isToShipFetching && myShipment.toShipList.length === 0) {
			getToShipListsAPI(_token, userData._id).then((response: any) => {
				if (response.statusCode === 200) {
					setIsToShipFetching(false);
					dispatch(setToShipList(response.data.items));
				}
			});
		} else if (myShipment.toShipList.length !== 0) {
			let updatedToShipList: any[] = [];

			myShipment.toShipList.forEach((toShip: any) => {
				toShip.to_ship_details.forEach((detail: any) => {
					const flattenedToShip = {
						...toShip,
						atl_id: detail.atl_id,
						plate_number: detail.plate_number,
						wheels: detail.wheels,
						total_capacity: detail.total_capacity,
						driver_id: detail.driver_id,
						driver_firstname: detail.driver_firstname,
						driver_lastname: detail.driver_lastname,
						license_number: detail.license_number,
						so_number: detail.so_number,
						is_confirm_to_load: detail.is_confirm_to_load,
					};
					updatedToShipList.push(flattenedToShip);
				});
			});

			setToShipLists(updatedToShipList);
		}
	}, [
		isToShipFetching,
		myShipment.toShipList.length,
		_token,
		userData._id,
		myShipment.toShipList,
		dispatch,
	]);

	// fetching close order
	useEffect(() => {
		if (isAllFetching && myShipment.allShipmentsList.length === 0) {
			getCloseOrdersAPI(_token, 6, userData._id).then((response: any) => {
				if (response.statusCode === 200) {
					setIsAllFetching(false);
					const disbursementId = response.data.items.map(
						(item: any, index: number) => {
							const formattedAmount = new Intl.NumberFormat("en-US", {
								style: "currency",
								currency: "PHP", // Change currency as per your requirement
							}).format(parseFloat(item.logistic_rate));
							return {
								...item,
								row_id: item.id ?? index,
								logistic_rate: formattedAmount,
								shipping_method_id:
									item.shipping_method_id === 2 ? "Pick-up" : "Delivery",
								is_disbursement_verified:
									item.is_disbursement_verified === 0
										? "For Verification"
										: "Verified",
							};
						}
					);
					dispatch(setShipmentsList(disbursementId));
				} else {
					console.log("failed to fetch disbursement list");
				}
			});
		} else if (myShipment.allShipmentsList.length !== 0) {
			let updatedListOfAllShipments: ShipmentListType[] = [];
			let updatedListOfPaymentVerification: ShipmentListType[] = [];
			// if (Array.isArray(paymentList.disbursementList)) {
			myShipment.allShipmentsList.forEach((shipment: any) => {
				const newShipment: ShipmentListType = {
					buyer_id: shipment.buyer_id,
					buyer_name: shipment.buyer_name,
					calculate_amount: shipment.calculate_amount,
					delivery_date: shipment.delivery_date,
					deposit_slip_url: shipment.deposit_slip_url,
					disbursement_details_id: shipment.disbursement_details_id,
					disbursement_slip_url: shipment.disbursement_slip_url,
					is_disbursement_verified: shipment.is_disbursement_verified,
					is_payment_verified: shipment.is_payment_verified,
					logistic_name: shipment.logistic_name,
					payment_details_id: shipment.payment_details_id,
					plate_number: shipment.plate_number,
					logistic_rate: shipment.logistic_rate,
					reverse_auction_id: shipment.reverse_auction_id,
					shipping_method_id: shipment.shipping_method_id,
					user_id: shipment.user_id,
					supplier_name: shipment.supplier_name,
					supplier_location: shipment.supplier_location,
					buyer_location: shipment.buyer_location,
				};

				updatedListOfAllShipments = [...updatedListOfAllShipments, newShipment];
				if (newShipment.is_disbursement_verified === "For Verification") {
					updatedListOfPaymentVerification.push(newShipment);
				}
			});
			setAllShipmentList(updatedListOfAllShipments);
			setPaymentVerificationList(updatedListOfPaymentVerification);
			setIsAllFetching(false);
		}
	}, [
		isAllFetching,
		userData._id,
		_token,
		myShipment.allShipmentsList.length,
		dispatch,
		myShipment.allShipmentsList,
	]);

	const dropdown_items: DropdownItemType[] = [
		{
			item_text: "Verify",
			onClick: () => setSelectedOption("Verify"),
		},
		{
			item_text: "Reconcile",
			onClick: () => setSelectedOption("Reconcile"),
		},
	];

	const clearErrors = () => {
		setIsCommentDetailsEmpty(false);
	};

	// handler for confirming atl
	const confirmATLHandler = async () => {
		setIsLoadingAPI(false);
		setIsButtonDisabled(false);
		setIsConfirmingATL(false);
		const data: any = {
			atw_id: selectedATL?.atl_details[0]?.atl_id,
			offer_booking_id: selectedATL?.offer_booking_id,
			driver_id: selectedATL?.atl_details[0]?.driver_id,
		};
		try {
			const response: any = await atlConfirmationAPI(_token, data);
			if (response.statusCode === 200) {
				const updatedList = myShipment.toShipList.map((item: any) =>
					item.offer_booking_id === selectedATL?.offer_booking_id
						? { ...item }
						: item
				);

				// Dispatch the updated list to the store
				dispatch(setToShipList(updatedList));

				if (socket && socket.readyState === WebSocket.OPEN) {
					const socketPayload = {
						title: "Payment Confirmation",
						message: `The Delivery is about to start`,
						user_id: selectedATL?.buyer_id,
						notification_type_id: 1,
						source_id: selectedATL?.offer_booking_id,
					};
					sendWebSocketMessage("sendNotification", socketPayload);
				}

				setIsLoadingAPI(true);
				setIsButtonDisabled(true);
				setIsConfirmingATL(true);
				setIsAtlModalOpen(false);
				setIsToastOpen(true);
				setToastMessage("Successfully Verified");
				setToastType("success");
				setTimeout(() => {
					setIsToastOpen(false);
				}, 3000);
			}
		} catch (error) {
			console.error("error response", error);
		}
	};

	const handlePDFDownload = async () => {
		const content: any = contentRef.current;
		try {
			setIsLoadingAPI(true);
			setIsDownloadingPDF(true);
			setIsButtonDisabled(true);
			// Capture the HTML content as an image
			const canvas = await html2canvas(content, { scale: 3 });

			const imgData = canvas.toDataURL("image/png");
			// Create a PDF document
			const pdfDoc = await PDFDocument.create();
			const page = pdfDoc.addPage([canvas.width, canvas.height]);

			// Embed the image in the PDF document
			const img = await pdfDoc.embedPng(imgData);
			page.drawImage(img, {
				x: 0,
				y: 0,
				width: canvas.width,
				height: canvas.height,
			});

			// Save the PDF and download it
			const pdfBytes = await pdfDoc.save();
			const blob = new Blob([pdfBytes], { type: "application/pdf" });
			saveAs(blob, "ATW.pdf");

			// Reset states after download is complete
			setIsLoadingAPI(false);
			setIsDownloadingPDF(false);
			setIsButtonDisabled(false);
		} catch {
			console.log("error");

			// Reset states if an error occurs
			setIsLoadingAPI(false);
			setIsDownloadingPDF(false);
			setIsButtonDisabled(false);
		}
	};

	// handler for updating status
	const verificationHandler = () => {
		let statusId: number;

		// Convert the string status back to a number
		switch (selectedForVerification?.is_disbursement_verified) {
			case "For Verification":
				statusId = 1;
				break;
			default:
				statusId = 0; // Default to 0 if status is unknown
		}

		const data: any = {
			status_id: statusId,
			disbursement_details_id: selectedForVerification?.disbursement_details_id,
			note: commentsDetails,
		};

		verificationAPI(_token, data).then((response: any) => {
			if (response.statusCode === 200) {
				setAllShipmentList((prevList) =>
					prevList.filter(
						(item) =>
							item.disbursement_details_id !==
							selectedForVerification?.disbursement_details_id
					)
				);
				setPaymentVerificationList((prevList) =>
					prevList.filter(
						(item) =>
							item.disbursement_details_id !==
							selectedForVerification?.disbursement_details_id
					)
				);
				clearErrors();
				dispatch(setCommentDetails(""));
				setIsModalOpen(false);
				setToastMessage("Successfully Verified");
				setToastType("success");
				setTimeout(() => {
					setIsToastOpen(false);
				}, 3000);
			}
		});
	};

	//fetching ATL
	useEffect(() => {
		if (isATL_Fetching && ATLlist.length === 0) {
			getATL_list_API(_token, userData._id).then((response: any) => {
				setIsATL_Fetching(false);
				if (response.statusCode === 200) {
					const newATLlist = response.data.items
						.filter((item: any) => item.atl_details.length > 0) // Filter items with atl_details > 0
						.map((item: any) => ({
							...item,
							buyer_shipping_method:
								item.buyer_shipping_method === 1 ? "Delivery" : "Pick-up",
						}));
					dispatch(setForATLTAbleList(newATLlist));
				}
			});
		} else if (ATLlist.length !== 0) {
			const updatedATLlist: ATLTablelistType[] = [];
			ATLlist.forEach((list: any) => {
				const newATLlist = {
					...list,
					buyer_shipping_method_label:
						list.buyer_shipping_method === 1 ? "Delivery" : "Pick-up",
				};
				updatedATLlist.push(newATLlist);
			});
			setForATLTablePageList(updatedATLlist);
			setIsATL_Fetching(false);
		}
	}, [isATL_Fetching, ATLlist, _token, dispatch, userData._id]);

	/////////////////////////////////////////////////////////////////////////
	const [isToShipModalOpen, setIsToShipModalOpen] = useState(false);
	//const [forToShipDetails, setForToShipDetails] = useState([]); //for redux
	const [selectedToShip, setSelectedToShip] = useState<
		ToShipDetailsType[] | undefined
	>();
	const [prevAtlIds, setPrevAtlIds] = useState<number | null>(null);

	useEffect(() => {
		if (toShipDetailsStateValue.length > 0) {
			const updatedToShipDetails = toShipDetailsStateValue.map((details) => ({
				...details,
			}));
			setForToShipPageDetails(updatedToShipDetails);
		}
	}, [toShipDetailsStateValue]);

	// this is for handler to show ongoing delivery details
	const handleDeliveryDetails = async (offer_booking_id: number) => {
		setIsDeliveryModalOpen(true);
		setDeliveryID(offer_booking_id);
	};

	// fetching the
	useEffect(() => {
		if (deliveryID !== 0 && isDeliveryModalOpen) {
			getDeliveryDetailsAPI(_token, userData._id, deliveryID).then(
				(response: any) => {
					if (response.statusCode === 200) {
						dispatch(setDeliveryDetailsLists(response.data.items));
					}
				}
			);
		}
	}, [deliveryID, isDeliveryModalOpen, _token, userData._id, dispatch]);

	const handleToShipRowClick = async (atl_id: string) => {
		try {
			let selected = forToShipPageDetails.find(
				(list: ToShipDetailsType) =>
					list.to_ship_details[0].atl_id === parseInt(atl_id)
			);
			const forATLlist = myShipment.toShipList.find(
				(list: ATLTablelistType) =>
					list.to_ship_details && // Ensure to_ship_details is defined
					list.to_ship_details.length > 0 && // Ensure to_ship_details is not empty
					list.to_ship_details[0].atl_id === parseInt(atl_id)
			);

			if (!selected) {
				if (!forATLlist) {
					setToastMessage("Shipment with the specified ID not found.");
					setToastType("error");
					setIsToastOpen(true);
					return;
				}
				// const atl_ids = forATLlist.to_ship_details.map(
				//   (details) => details.atl_id
				// );
				const { logistic_id, to_ship_details } = forATLlist;
				const atl_ids = to_ship_details.map((details) => details.atl_id);
				let atl_id = 0;
				if (atl_ids.length === 1) {
					atl_id = atl_ids[0];
				} else if (atl_ids.length > 1) {
					setToastMessage("Shipment has multiple ATL.");
					setToastType("error");
					setIsToastOpen(true);
				}
				const isAtlIdChanged = atl_id !== prevAtlIds;
				const shouldFetch = isAtlIdChanged; // add

				if (shouldFetch) {
					const response: any = await getToShipDetailsAPI(
						_token,
						logistic_id, //parseInt(logistic_id),
						atl_id
					);
					if (response.statusCode === 200) {
						const newToShipDetails = response.data.items.map((item: any) => ({
							...item,
						}));
						dispatch(setForToShipDetails(newToShipDetails));
						selected = newToShipDetails.find(
							(list: ToShipDetailsType) =>
								list.to_ship_details[0].atl_id === atl_id
						);
					}
					setPrevAtlIds(atl_id);
				}
			}
			if (selected) {
				setSelectedToShip([selected]);
				setIsToShipModalOpen(true);
			}
		} catch (error) {
			console.error("Error in handleToShipRowClick:", error);
		}
	};

	const handleToConfirmDeliveryClick = async (offerBookingsId: number) => {
		try {
			const data = {
				offer_bookings_id: offerBookingsId,
			};
			const response: any = await updateConfirmToDeliverAPI(_token, data);
			if (response.statusCode === 200) {
				setToastMessage("Delivery confirmed");
				setToastType("success");
				setIsToastOpen(true);
				setTimeout(() => {
					setIsToastOpen(false);
				}, 3000);
			}
		} catch (error) {
			console.error("Error in handleToShipRowClick");
		}
	};

	// fetching delivery list
	useEffect(() => {
		if (isDeliveryListFetching && myShipment.deliveryLists.length === 0) {
			getDeliveryListAPI(_token, userData._id).then((response: any) => {
				if (response.statusCode === 200) {
					setIsDeliveryListFetching(false);
					// Updating the delivery lists before dispatching
					const updatedDeliveryLists = response.data.items.map(
						(delivery: any) => {
							// Change order_status if order_status_id is 9 or 10
							let updatedStatus = delivery.order_status;
							if (
								delivery.order_status_id === 9 ||
								delivery.order_status_id === 10
							) {
								updatedStatus = "On-going";
							}
							// Get the date_delivered from initial_delivery_receipt
							const dateDelivered =
								delivery.initial_delivery_receipt.length > 0
									? new Date(
											delivery.initial_delivery_receipt[0].date_delivered
									  ).toLocaleString("en-PH", {
											timeZone: "Asia/Manila", // Convert to Philippine Time
									  })
									: "N/A"; // Handle case where the array might be empty

							// Get the received at from initial_delivery_receipt
							const dateCompleted =
								delivery.initial_delivery_receipt.length > 0 &&
								delivery.initial_delivery_receipt[0].received_at
									? new Date(
											delivery.initial_delivery_receipt[0].received_at
									  ).toLocaleString("en-PH", {
											timeZone: "Asia/Manila", // Convert to Philippine Time
									  })
									: "-- --"; // Handle null or empty case

							return {
								...delivery,
								order_status: updatedStatus,
								date_delivered: dateDelivered,
								date_completed: dateCompleted,
							};
						}
					);
					// Dispatch the updated delivery lists
					dispatch(setDeliveryLists(updatedDeliveryLists));
				}
			});
		} else if (myShipment.deliveryLists.length !== 0) {
			// Create updated delivery lists if already available
			const updatedDeliveryList = myShipment.deliveryLists.map(
				(delivery: any) => {
					let updatedStatus = delivery.order_status;
					if (
						delivery.order_status_id === 9 ||
						delivery.order_status_id === 10
					) {
						updatedStatus = "On-going";
					}

					// Get the date_delivered from initial_delivery_receipt
					const dateDelivered =
						delivery.initial_delivery_receipt.length > 0
							? new Date(
									delivery.initial_delivery_receipt[0].date_delivered
							  ).toLocaleString("en-PH", {
									timeZone: "Asia/Manila", // Convert to Philippine Time
							  })
							: "N/A"; // Handle case where the array might be empty

					// Get the received at from initial_delivery_receipt
					const dateCompleted =
						delivery.initial_delivery_receipt.length > 0 &&
						delivery.initial_delivery_receipt[0].received_at
							? new Date(
									delivery.initial_delivery_receipt[0].received_at
							  ).toLocaleString("en-PH", {
									timeZone: "Asia/Manila", // Convert to Philippine Time
							  })
							: "-- --"; // Handle null or empty case

					return {
						...delivery,
						order_status: updatedStatus,
						buyer_name: delivery.buyer_name,
						supplier_name: delivery.supplier_name,
						date_delivered: dateDelivered,
						date_completed: dateCompleted,
					};
				}
			);
			setDeliveryList(updatedDeliveryList);
		}
	}, [
		_token,
		userData._id,
		isDeliveryListFetching,
		dispatch,
		myShipment.deliveryLists,
		myShipment.deliveryLists.length,
	]);

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						<ToasterComponent
							isOpen={isToastOpen}
							label={toastMessage}
							onClose={setIsToastOpen}
							type={toastType}
						/>

						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="Shipments"
						/>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<NavTabComponent
							key={Math.random()}
							nav_type="secondary"
							active_tab={activeNav}
							nav_items={nav_items}
							setActiveNavHandler={setActiveNavTab}
						/>
						{/**Start here*/}
						<div className="flex flex-wrap justify-center">
							<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
								{activeNav === "All" && (
									<>
										{isAllFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={allHeader}
												tableData={allShipmentList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="disbursement_details_id"
												handleRowClick={(disbursement_details_id: string) => {
													setSelectedRowId(parseInt(disbursement_details_id));
													setIsModalOpen(true);
												}}
												emptyState={{
													primaryMessage: "No Orders available.",
													secondaryMessage: "",
													buttonText: "Create Order",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "Payment Verification" && (
									<>
										{isAllFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={allHeader}
												tableData={paymentVerificationList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="disbursement_details_id"
												handleRowClick={(disbursement_details_id: string) => {
													setSelectedRowId(parseInt(disbursement_details_id));
													setIsModalOpen(true);
												}}
												emptyState={{
													primaryMessage: "No Orders available.",
													secondaryMessage: "",
													buttonText: "Create Order",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "ATL" && (
									<>
										{isATL_Fetching ? (
											<>
												<div className="-my-2">
													<Skeleton height={40} count={1} />
													<Skeleton height={40} count={5} />
												</div>
											</>
										) : (
											<TableComponent
												tableHeader={ATLheader}
												tableData={forATLTablePageList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="offer_booking_id"
												handleRowClick={(offer_booking_id: string) => {
													setSelectedRowId(parseInt(offer_booking_id));
													setIsAtlModalOpen(true);
												}}
												emptyState={{
													primaryMessage: "No ATL available.",
													secondaryMessage: "",
													buttonText: "Create ATL",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "To Ship" && (
									<>
										{isAllFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={ToShipHeader}
												tableData={toShipLists}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="atl_id"
												handleRowClick={handleToShipRowClick}
												emptyState={{
													primaryMessage: "No Orders available.",
													secondaryMessage: "",
													buttonText: "Create Order",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "Delivery" && (
									<>
										{isAllFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={deliveryHeader}
												tableData={deliveryList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="offer_booking_id"
												handleRowClick={(offer_booking_id: string) =>
													handleDeliveryDetails(parseInt(offer_booking_id))
												}
												emptyState={{
													primaryMessage: "No Delivery list.",
													secondaryMessage: "",
													buttonText: "Create Order",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
							</div>
						</div>
						{/** Modal Component */}
						<ModalComponent
							isOpen={isModalOpen}
							onCloseHandler={() => {
								setIsModalOpen(false);
								setSelectedOption("");
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Payment Verification
									</span>
								</div>
							}
							body={
								<>
									{selectedForVerification !== undefined && (
										<>
											<div className="mx-5 items-center py-16">
												<div className="flex flex-col justify-between gap-8 md:flex-row -translate-y-9">
													<div className="flex flex-col gap-5 w-full">
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Buyer Name
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md w-full">
																<p className="text-base text-gray-700">
																	{selectedForVerification.buyer_name}
																</p>
															</div>
														</div>
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Shipment Type
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md">
																<p className="text-base text-gray-700">
																	{selectedForVerification.shipping_method_id}
																</p>
															</div>
														</div>
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Delivery Date
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md">
																<p className="text-base text-gray-700">
																	{selectedForVerification.delivery_date}
																</p>
															</div>
														</div>
													</div>

													<div className="md:ml-10 md:w-full">
														<div className="flex flex-col gap-1 mb-5">
															<span className="text-sm text-gray-400">
																Amount
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md w-full">
																<p className="text-base text-gray-700">
																	{selectedForVerification.logistic_rate}
																</p>
															</div>
														</div>
														<div className="items-center">
															<img
																className="w-full h-52 md:h-56"
																src={
																	selectedForVerification.disbursement_slip_url
																}
																alt="uploaded"
															/>
														</div>
														<div className="mt-4 mr-5">
															<DropdownOptionComponent
																items={dropdown_items}
																main_text="Options"
															/>
														</div>
														<div className="pt-16 ml-6">
															{selectedOption === "Verify" && (
																<ButtonComponent
																	text="Confirm"
																	utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer
																	 duration-500 rounded-full font-normal md:w-11/12 w-full`}
																	onClick={verificationHandler}
																/>
															)}
														</div>
													</div>
												</div>
												{selectedOption === "Reconcile" && (
													<>
														<textarea
															id="txtcommentsdetails"
															name="txtcommentsdetails"
															value={commentsDetails}
															placeholder="Please input reason"
															rows={3}
															className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
																isCommentDetailsEmpty ? "red-600" : "gray-300"
															} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
															onChange={(e) =>
																fieldChangeHandler(
																	e.target.value,
																	"txtcommentsdetails"
																)
															}
														/>
														<div className="mt-5">
															<ButtonComponent
																text="Send"
																utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer"
																	 duration-500 rounded-full font-normal w-full`}
																onClick={verificationHandler}
															/>
														</div>
													</>
												)}
											</div>
										</>
									)}
								</>
							}
						/>
						{/** Modal ATL Component */}
						<ModalComponent
							isOpen={isAtlModalOpen}
							onCloseHandler={() => {
								setIsAtlModalOpen(false);
							}}
							isXL={true}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										ATL Details
									</span>
								</div>
							}
							body={
								<>
									<div className="flex justify-center mt-5 gap-5 flex-col mx-12 md:flex-row">
										<ButtonComponent
											text={`${
												isButtonDisabled ? "Confirming" : "Confirm ATL"
											}`}
											icon={
												isLoadingAPI && (
													<Lottie
														className="md:w-5 w-5 h-auto"
														animationData={CircularLoading}
														loop={true}
													/>
												)
											}
											disabled={isButtonDisabled}
											utils={`bg-dealogikal-200 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
												hover:bg-dealogikal-50 duration-500 rounded-full font-normal md:w-72 w-full
												${isConfirmingATL ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
												`}
											onClick={confirmATLHandler}
										/>
										<ButtonComponent
											text={`${
												isButtonDisabled ? "Downloading" : "Download ATL"
											}`}
											icon={
												isLoadingAPI && (
													<Lottie
														className="md:w-5 w-5 h-auto"
														animationData={CircularLoading}
														loop={true}
													/>
												)
											}
											disabled={isButtonDisabled}
											utils={`border border-dealogikal-200 text-dealogikal-200 md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
												hover:bg-dealogikal-200 hover:text-white duration-500 rounded-full font-normal md:w-72 w-full
												${isDownloadingPDF ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
												`}
											onClick={handlePDFDownload}
										/>
									</div>
									{selectedATL !== undefined && (
										<div
											ref={contentRef}
											className="max-w-4xl mx-auto p-8 bg-white shadow-lg my-10"
										>
											<h1 className="text-xl md:text-3xl font-bold text-center mb-8">
												DEALOGICAL CORPORATION
											</h1>

											<div className="mb-8 grid grid-cols-2 gap-4 text-sm lg:text-base w-full">
												<div>
													<p className="flex gap-4 items-center w-80">
														<span className="w-24">DATE</span>
														<span>:</span>
														<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
															{selectedATL.requested_delivery_date}
														</span>
													</p>
													<p className="flex gap-4 items-center w-80">
														<span className="w-24">POD</span>
														<span>:</span>
														<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
															{selectedATL.requested_delivery_date}
														</span>
													</p>
													{selectedATL?.atl_details?.length > 0 && (
														<p className="flex gap-4 items-center w-80">
															<span className="w-24">SO #</span>
															<span>:</span>
															<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
																{selectedATL.atl_details[0]?.so_number || "N/A"}
															</span>
														</p>
													)}
												</div>
											</div>

											<h2 className="text-2xl font-bold text-center mb-4">
												AUTHORITY TO LOAD
											</h2>
											{selectedATL?.atl_details?.length > 0 && (
												<p className="mb-4 text-sm text-black">
													Please authorize our driver{" "}
													<span className="font-bold underline underline-offset-2">
														{selectedATL.atl_details[0]?.driver_firstname}{" "}
														{selectedATL.atl_details[0]?.driver_lastname}
													</span>{" "}
													to withdraw the following products from your terminal.
												</p>
											)}

											<p className="mb-2 font-semibold">
												Details are follows :
											</p>

											<table className="w-full max-w-3xl mx-auto mb-10 items-center">
												<thead>
													<tr className="border-b text-base">
														<th className="text-left p-2">PRODUCT</th>
														<th className="text-left p-2">QUANTITY (liters)</th>
														<th className="text-left p-2">REMARKS</th>
													</tr>
												</thead>
												<tbody>
													{selectedATL.items.map((item: any, index: number) => (
														<tr key={index} className="border-b text-sm">
															<td className="p-2">{item.commodity_name}</td>
															<td className="p-2">{item.ordered_volume}</td>
															<td className="p-2">{item.unit}</td>
														</tr>
													))}
												</tbody>
											</table>

											<div className="grid grid-cols-1 gap-4 mt-5 text-sm md:text-base">
												<p className="flex gap-4">
													<span className="font-semibold w-36">HAULER</span>
													<span>: Dealogical</span>
												</p>

												{selectedATL?.atl_details?.length > 0 && (
													<p className="flex gap-4">
														<span className="font-semibold w-36">PLATE #</span>
														<span>
															: {selectedATL.atl_details[0]?.plate_number}
														</span>
													</p>
												)}
												<p className="flex gap-4">
													<span className="font-semibold w-36">
														DESTINATION
													</span>
													<span>: {selectedATL.buyer_shipping_method}</span>
												</p>
												{selectedATL?.atl_details?.length > 0 && (
													<p className="flex gap-4">
														<span className="font-semibold w-36">
															LICENSE NO.
														</span>
														<span>
															: {selectedATL.atl_details[0]?.license_number}
														</span>
													</p>
												)}
												{selectedATL?.atl_details?.length > 0 && (
													<p className="flex gap-4">
														<span className="font-semibold w-36">WHEELS</span>
														<span>: {selectedATL.atl_details[0]?.wheels}</span>
													</p>
												)}
												{/* <p className="flex gap-4">
													<span className="font-semibold w-36">
														PCS. OF SEAL
													</span>
													<span>: 13 pcs.</span>
												</p> */}
												{selectedATL?.atl_details?.length > 0 && (
													<p className="flex gap-4">
														<span className="font-semibold w-36">CAPACITY</span>
														<span>
															:{" "}
															{parseInt(
																selectedATL.atl_details[0]?.total_capacity,
																10
															) / 1000}
															kl capacity
														</span>
													</p>
												)}
											</div>

											<div className="flex flex-col mt-10 items-start">
												<p className="border-b border-gray-800 text-start inline-block font-bold">
													KLYNEA MARTINI S. YAUN
												</p>
												<span className="text-center text-sm mt-1 ml-5">
													Authorized Signatory
												</span>
											</div>
										</div>
									)}
								</>
							}
						/>
						{/** Modal Ship Component */}
						<ModalComponent
							isOpen={isToShipModalOpen}
							onCloseHandler={() => {
								setIsToShipModalOpen(false);
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										To Ship Details
									</span>
								</div>
							}
							body={
								<div className="flex flex-col h-full">
									{selectedToShip && selectedToShip.length > 0 ? (
										<div className="flex flex-col flex-grow overflow-auto">
											{selectedToShip.map(
												(toShipDetails: any, index: number) => (
													<div key={index} className="mx-5 py-16">
														<div className="flex flex-col justify-between gap-8 md:flex-row">
															<div className="flex flex-col gap-5 w-full">
																<div className="flex flex-col gap-1">
																	<span className="text-sm text-gray-400">
																		Buyer Name:
																	</span>
																	<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																		<p className="pl-3 text-gray-700 sm">
																			{toShipDetails.buyer_name}
																		</p>
																	</div>
																	<span className="text-sm text-gray-400">
																		Buyer Location:
																	</span>
																	<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																		<p className="pl-3 text-gray-700 sm"></p>
																	</div>
																</div>
															</div>
															<div className="flex flex-col gap-5 w-full">
																<div className="flex flex-col gap-1">
																	<span className="text-sm text-gray-400">
																		Supplier Depot Location:
																	</span>
																	<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																		<p className="pl-3 text-gray-700 sm">
																			{toShipDetails.supplier_depot}
																		</p>
																	</div>
																	<span className="text-sm text-gray-400">
																		Delivery Date:
																	</span>
																	<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																		<p className="pl-3 text-gray-700 sm">
																			{toShipDetails.requested_delivery_date}
																		</p>
																	</div>
																</div>
															</div>
														</div>
													</div>
												)
											)}
										</div>
									) : (
										<div className="flex justify-center items-center flex-grow">
											<p>No To Ship.</p>
										</div>
									)}

									{/* Button Container */}
									<div className="flex justify-center mt-10 mb-5">
										<ButtonComponent
											text={`${
												isButtonDisabled ? "Confirmed to Deliver" : "Deliver"
											}`}
											icon={
												isLoadingAPI && (
													<Lottie
														className="md:w-5 w-5 h-auto"
														animationData={CircularLoading}
														loop={true}
													/>
												)
											}
											disabled={isButtonDisabled}
											utils={`border border-dealogikal-200 text-dealogikal-200 md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2 hover:bg-dealogikal-200 hover:text-white duration-500 rounded-full font-normal md:w-72 w-full ${
												isDownloadingPDF
													? "cursor-not-allowed opacity-50"
													: "cursor-pointer"
											}`}
											onClick={() => {
												if (selectedToShip && selectedToShip.length > 0) {
													const firstOfferBookingId =
														selectedToShip[0].offer_booking_id;
													handleToConfirmDeliveryClick(firstOfferBookingId);
												}
											}}
										/>
									</div>
								</div>
							}
						/>
						{/** Modal Delivery component */}
						<ModalComponent
							isOpen={isDeliveryModalOpen}
							onCloseHandler={() => {
								setIsDeliveryModalOpen(false);
							}}
							isXL={true}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Delivery Receipt Details
									</span>
								</div>
							}
							body={
								<>
									<div
										className="w-full bg-gray-50 shadow-md rounded-lg px-20 py-5 h-[70rem] "
										ref={contentRef}
									>
										<div className="flex justify-between items-center mb-5">
											<img src={logo} alt="logo" className="h-40 w-52" />

											<div className="text-center flex-1">
												<h1 className="text-dealogikal-100 font-bold text-xl mb-2">
													DEALOGIKAL CORP.
												</h1>
												<p className="text-sm mb-2">
													Kiener Energy Centre Bldg., Kiener Hills, Matumbo
													Road, Pusok, Lapulapu City, Cebu
												</p>
												<p className="text-sm mb-2">(032) 411-0303</p>
												<a
													href="https://www.dealogikal.com"
													className="text-blue-500 text-sm"
												>
													https://www.dealogikal.com
												</a>
											</div>

											<div className="text-right flex-shrink-0">
												<button className="font-bold text-lg">
													Deliver Receipt
												</button>
											</div>
										</div>

										<div className="mb-8 grid grid-cols-2 gap-4 text-sm lg:text-base w-full">
											<div>
												<p className="flex gap-4 items-center">
													<span className="w-30">Customer Name</span>
													<span>:</span>
													<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
														{myShipment.deliveryDetails[0]?.buyer_name}
													</span>
												</p>
												<p className="flex gap-4 items-center">
													<span className="w-30">Address</span>
													<span>:</span>
													<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
														{
															myShipment.deliveryDetails[0]
																?.initial_delivery_receipt[0].delivery_address
														}
														lapu-lapu
													</span>
												</p>
											</div>
											<div>
												<p className="flex gap-4 items-center">
													<span className="w-24">Date</span>
													<span>:</span>
													<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
														{
															myShipment.deliveryDetails[0]
																?.requested_delivery_date
														}
													</span>
												</p>
											</div>
										</div>

										<table className="min-w-full border border-gray-500 text-center my-10">
											<thead>
												<tr className="bg-gray-100">
													<th
														scope="col"
														className="py-4 px-6 text-sm font-semibold text-dealogikal-200 border-r border-gray-500"
													>
														Quantity Ordered
													</th>
													<th
														scope="col"
														className="py-4 px-6 text-sm font-semibold text-dealogikal-200 border-r border-gray-500"
													>
														Quantity Received
													</th>
													<th
														scope="col"
														className="py-4 px-6 text-sm font-semibold text-dealogikal-200"
													>
														Description
													</th>
												</tr>
											</thead>

											{myShipment.deliveryDetails[0]?.items.map((item: any) => (
												<tbody className="bg-white" key={item.commodity_id}>
													<tr className="border-t border-gray-500">
														<td className="py-4 px-6 text-sm text-gray-900 border-r border-gray-500">
															{item.ordered_volume}
														</td>
														<td className="py-4 px-6 text-sm text-gray-900 border-r border-gray-500"></td>
														<td className="py-4 px-6 text-sm text-gray-900">
															{item.commodity_name}
														</td>
													</tr>
												</tbody>
											))}
										</table>
										{myShipment.deliveryDetails[0]
											?.initial_delivery_receipt[0] && (
											<div className="mt-20 border-t border-gray-500 pt-10">
												<div className="space-y-6">
													{/* Access the first item in the array */}

													<div className="grid grid-cols-3 gap-10">
														<div className="flex items-center">
															<span className="w-48 font-semibold">
																Driver's Name and Signature:
															</span>
															<span className="flex-1 border-b border-gray-600 ml-4">
																{myShipment.deliveryDetails[0]
																	.initial_delivery_receipt[0]
																	.driver_firstname +
																	" " +
																	myShipment.deliveryDetails[0]
																		.initial_delivery_receipt[0]
																		.driver_lastname}
															</span>
														</div>
														<div className="flex items-center">
															<span className="w-48 font-semibold">
																Plate Number:
															</span>
															<span className="flex-1 border-b border-gray-600 ml-4">
																{
																	myShipment.deliveryDetails[0]
																		.initial_delivery_receipt[0].plate_number
																}
															</span>
														</div>
														<div className="flex items-center">
															<span className="w-48 font-semibold">
																Checked by:
															</span>
															<span className="flex-1 border-b border-gray-600 ml-4">
																{
																	myShipment.deliveryDetails[0]
																		.initial_delivery_receipt[0].check_by
																}
															</span>
														</div>
														<div className="flex items-center">
															<span className="w-48 font-semibold">
																Seal No:
															</span>
															<span className="flex-1 border-b border-gray-600 ml-4"></span>
														</div>
														<div className="flex items-center">
															<span className="w-48 font-semibold">
																Certified By:
															</span>
															<span className="flex-1 border-b border-gray-600 ml-4">
																{
																	myShipment.deliveryDetails[0]
																		.initial_delivery_receipt[0].certified_by
																}
															</span>
														</div>
													</div>
												</div>
											</div>
										)}

										<div className="mt-20 border-t border-gray-500 pt-10">
											<span>Received the above good order & condition</span>
										</div>
										<div className="mt-20 border-t border-gray-500 pt-10">
											<div className="grid grid-cols-2 gap-10">
												<div className="flex flex-col items-start">
													<p className="text-center border-b w-80 border-gray-800 inline-block font-bold">
														KLYNEA MARTINI S. YAUN
													</p>
													<span className="text-center text-sm mt-1 ml-5">
														(Customer Signature over Printed Name)
													</span>
												</div>

												<div className="flex flex-col items-start">
													<div className="flex items-center">
														<span className="w-36 font-semibold">
															Date Received:
														</span>
														<span className="flex-1 border-b border-gray-600 ml-4"></span>
													</div>
													<div className="flex items-center mt-2">
														<span className="w-36 font-semibold">
															Time Received:
														</span>
														<span className="flex-1 border-b border-gray-600 ml-4"></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							}
						/>
					</>
				}
			/>
		</>
	);
};

export default ShipmentsComp;
